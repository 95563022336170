import { inject } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { Router } from '@angular/router';
import { map } from 'rxjs';

export const loggedInGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.isLoggedIn().pipe(
    map(user => {
      if (user) {
        return true;
      }
      router.navigateByUrl('/login');
      return false;
    })
  );
}

export const notLoggedInGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.isLoggedIn().pipe(
    map(user => {
      if (user) {
        //URL definida como URL base del proyecto
        router.navigateByUrl('/');
        return false;
      }
      return true;
    })
  );
}
