import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { EnumToastTypes, ConstToastType } from '@interfaces/toast.interfaces';
import { Icon } from '@interfaces/icon.interfaces';

@Component({
  selector: 'app-toast-v2',
  templateUrl: './toast-v2.component.html',
  styleUrls: ['./toast-v2.component.scss'],
  standalone: true,
  imports: [MatIconModule, CommonModule],
})
export class ToastV2Component implements OnInit {
  message?: string;
  icon?: Icon = 'error';
  toastType?: string;
  toastClass?: string;
  url?: string;
  domains?: string[] = [];

  constructor(
    private snackRef: MatSnackBarRef<ToastV2Component>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    this.message = data.message;
    this.toastType = data.type;
    this.url = data.url;
    this.domains = data.domains; 
  }

  ngOnInit(): void {
    switch (this.toastType) {
      case ConstToastType.defualt:
        this.icon = 'check-circle';
        break;
      case ConstToastType.error:
        this.icon = 'error';
        break;
      case ConstToastType.success:
        this.icon = 'check_circle';
        break;
      case ConstToastType.warning:
        this.icon = 'error';
    }
  }

  close() {
    this.snackRef.dismiss();
  }
}
