import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PasswordService } from '@services/password/password.service';

export const queryTokenEmailGuard = (route: ActivatedRouteSnapshot) => {
  const token = route.queryParams['token'];
  const email = route.queryParams['email'];
  const router = inject(Router);
  const passwordService = inject(PasswordService);
  if (token && email) {
    return passwordService.validateToken(email, token).pipe(
      map((res) => {
        if (res.data) {
          return true;
        }
        router.navigate(['/login/forgot-password'], {
          state: { val: true },
        });
        return false;
      })
    );
  } else {
    router.navigate(['/login/forgot-password'], {
      state: { val: true },
    });
    return of(false);
  }
};
