import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Iemail } from '@interfaces/email.interfaces';
import {
  IApiResTokenValidate,
  IResetPassword,
} from '@interfaces/password.interfaces';
import { ConstToastType } from '@interfaces/toast.interfaces';
import { ApiService } from '@services/api/api.service';
import { ToastService } from '@services/toast/toast.service';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private toastService: ToastService
  ) {}

  forgotPassword(email: string): Observable<Iemail> {
    return this.apiService.post<Iemail>('/password/email', { email });
  }

  validateToken(
    email: string | null,
    token: string | null
  ): Observable<IApiResTokenValidate> {
    return this.apiService
      .post<IApiResTokenValidate>('/password/validate', {
        email,
        token,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 500) {
            this.router.navigateByUrl('/login');
            this.toastService.show(
              'Error en el envio de datos',
              ConstToastType.error
            );
            return throwError('server error');
          } else {
            this.router.navigate(['/login/forgot-password'], {
              state: { false: false },
            });
          }
          return throwError('server error');
        })
      );
  }

  resetPassword(
    email: string,
    token: string,
    password: string,
    password_confirmation: string
  ): Observable<IResetPassword> {
    return this.apiService.post<IResetPassword>('/password/reset', {
      email,
      token,
      password,
      password_confirmation,
    });
  }
}
