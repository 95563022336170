import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const canActivate = () => {
  const router = inject(Router);
  const http = inject(HttpClient);
  return http.get('/status', { observe: 'response' }).pipe(
    map((response) => {
      if (response.status === 503) {
        return false;
      } else {
        return true;
      }
    }),
    catchError((error) => {
      if (error.status === 503) {
        return of(false);
      }
      return of(router.createUrlTree(['/login']));
    })
  );
};
