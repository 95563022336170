import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackBarControlService {
  private showSnackbarBS = new BehaviorSubject<boolean>(true);
  public showSnackbar$ = this.showSnackbarBS.asObservable();

  constructor() {}

  setShowSnackbar(value: boolean) {
    this.showSnackbarBS.next(value);
  }
}
