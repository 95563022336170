import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket!: Socket;

  constructor() { }

  initializeSocket(userId: string): void {
    const config: SocketIoConfig = { url: environment.NODE_JS_URL, options: { query: { userId } } };
    this.socket = new Socket(config);
  }

  reconnectSocket(userId: string): void {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.initializeSocket(userId);
  }

  disconnectSocket(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  sendMessage(msg: string) {
    this.socket.emit('message', msg);
  }

  getMessage() {
    return this.socket.fromEvent<string>('message');
  }

  getNotification() {
    return this.socket.fromEvent<string>('notification');
  }
}
