import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarControlService } from '@services/snackBarControl/snack-bar-control.service';
import { ToastV2Component } from 'src/app/ui/toast-v2/toast-v2.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private showSnackbar = true;

  constructor(
    private snackBar: MatSnackBar,
    private snackbarControlService: SnackBarControlService
  ) {
    this.snackbarControlService.showSnackbar$.subscribe((value) => {
      this.showSnackbar = value;
    });
  }

  show(
    message: string,
    type: string,
    url: string = '',
    domains: string[] = []
  ): void {
    if (this.showSnackbar) {
      const config = new MatSnackBarConfig();
      config.data = { message, type, url, domains };
      config.panelClass = ['custom-toast'];
      config.verticalPosition = 'top';
      config.horizontalPosition = 'end';
      config.duration = 5000;
      this.snackBar.openFromComponent(ToastV2Component, config);
    }
  }
}
