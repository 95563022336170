<div class="container">
  <mat-card>
    <mat-card-content>
      <div class="header">
        <div class="title">En mantenimiento</div>
        <mat-icon svgIcon="construction"></mat-icon>
      </div>
      <div class="description">
        El sitio se encuentra en mantenimiento, intente nuevamente más tarde
      </div>
      <button class="btn-dialog-v6">Volver a intentar</button>
    </mat-card-content>
  </mat-card>
</div>
