import { Icon } from './icon.interfaces';

export interface IToast {
  toastMessage: string;
  toastType: 'success' | 'error' | 'warning' | 'default';
  toastIcon: Icon;
  duration?: number;
}

export enum EnumToastTypes {
  success,
  error,
  warning,
  default,
}

export const ConstToastType = {
  success: 'success-toast',
  error: 'error-toast',
  warning: 'warning-toast',
  defualt: 'default-toast',
  url: 'url',
};
