<div class="container">
  <div class="sidebar">
    <div>
      <div class="logo">
        <img
          appImageSrc="images/Logo-compras.svg"
          alt="logo portal de compras"
        />
      </div>
      <div class="title">
        {{ text }}
      </div>
    </div>
    <div class="footer">
      <img appImageSrc="images/Logos-AGD.svg" alt="grupo de logos" />
      <div class="logos">
        <img class="t6" appImageSrc="images/Logo-T6.svg" alt="logo de T6" />
        <img
          class="promaiz"
          appImageSrc="images/Promaiz.svg"
          alt="logo de Promaiz"
        />
      </div>
    </div>
  </div>
  <div class="centered-component">
    <ng-content></ng-content>
  </div>
</div>

<div class="video">
  <video
    muted
    autoplay
    loop
    playsinline
    oncanplay="this.play()"
    onloadedmetadata="this.muted = true"
  >
    <source
      src="https://www.agd.com.ar/sites/default/files/intro-agd.mp4"
      type="video/mp4"
    />
  </video>
</div>
