<div class="toast-container" [ngClass]="toastType">
  <mat-icon class="icon-type">{{ icon }}</mat-icon>
  <div>
    <span>{{ message }}</span>
    @if (domains && domains.length) {
      <ul>
        @for (domain of domains; track domain) {
          <li>{{ domain }}</li>
        }
      </ul>
    }
    @if (url?.length) {
      <a target="_blank" [href]="url">{{ url }}</a>
    }
  </div>
  <mat-icon class="icon-close" (click)="close()">close</mat-icon>
</div>
