import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenExpiredInterceptorInterceptor } from '@interceptors/logout-interceptor/token-expired-interceptor.interceptor';
import { RedirectInterceptor } from '@interceptors/redirect/redirect.interceptor';
import { FormsModule } from '@angular/forms';
import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
import { MaintenanceInterceptor } from '@interceptors/redirect/maintenance.interceptors';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SocketService } from '@services/socket/socket.service';
import { AuthService } from '@services/auth/auth.service';

export function initializeAppFactory(authService: AuthService, socketService: SocketService) {
  return () => new Promise<void>((resolve) => {
    const userId = authService.getCurrentUserId();
    if (userId) {
      socketService.initializeSocket(userId.toString());
    }
    resolve(); 
  });
}


registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  providers: [
    AuthService,
    SocketService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, SocketService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenExpiredInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true,
      },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-Ar' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
