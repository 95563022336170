import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerOverlayComponent } from '@sharedComponents/spinner/spinner-overlay/spinner-overlay.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private overlayRef: OverlayRef | null = null;
  constructor(private overlay: Overlay) {}

  private spinnerSubject = new BehaviorSubject<boolean>(false);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private activeRequest: number = 0;
  spinnerState$ = this.spinnerSubject.asObservable();

  private loading: boolean = false;

  startLoading() {
    if (this.activeRequest === 0) {
      if (!this.overlayRef) {
        this.loadingSubject.next(true);
        this.open();
      }
    }
    this.activeRequest++;
  }

  stopLoading() {
    if (this.activeRequest > 0) {
      this.activeRequest--;
      if (this.activeRequest === 0) {
        if (this.overlayRef) {
          this.loadingSubject.next(false);
          this.overlayRef.detach();
          this.overlayRef = null;
        }
      }
    }
  }

  deleteLoading() {
    this.activeRequest = 0;
    if (this.overlayRef) {
      this.loadingSubject.next(false);
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  isLoading() {
    return this.loading;
  }

  open() {
    if (!this.overlayRef) {
      const overlayConfig = {
        hasBackdrop: false,
        backdropClass: 'overlay-transparent-backdrop',
        positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically(),
      };
      this.overlayRef = this.overlay.create(overlayConfig);
    }

    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
    this.overlayRef.attach(spinnerOverlayPortal);
  }
}
